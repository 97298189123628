// Default fallback in case the header items can't be loaded via fetch.
// Structure should match the "items" field in /config/mcp-header/v2/menuitems.
// See readme.txt file in that directory for more information.
module.exports = [
  {
    id: 'orders',
    text: 'Orders',
    svg: 'shopping-cart-full',
    subItems: [
      {
        id: 'oops',
        text: 'Order Operations Tool',
        url: 'https://orderoperations.cimpress.io',
      },
      {
        id: 'opa',
        text: 'Order Placement App',
        url: 'https://order-placement.mex.cimpress.io',
      },
      {
        id: 'pom',
        text: 'Production Order Manager',
        url: 'https://pom.at.cimpress.io/',
      },
      {
        id: 'invoiceManager',
        text: 'Invoice Manager',
        url: 'https://invoicemanager.financialflows.cimpress.io/',
      },
    ],
  },
  {
    id: 'products',
    text: 'Products',
    svg: 't-shirt',
    subItems: [
      {
        id: 'productManager',
        text: 'Product Manager',
        url: 'https://productmanager.products.cimpress.io/',
      },
      {
        id: 'pricingManager',
        text: 'Pricing Manager',
        url: 'https://pricingmanager.financialflows.cimpress.io/',
      },
      {
        id: 'productCatalog',
        text: 'Catalog',
        url: 'https://catalog.products.cimpress.io',
      },
    ],
  },
  {
    id: 'customization',
    text: 'Artwork Prep and Customization',
    svg: 'design-mug',
    subItems: [
      {
        id: 'checkit',
        text: 'Check It',
        url: 'https://checkit.documents.cimpress.io/',
      },
      {
        id: 'prepress',
        text: 'Print Profile Manager',
        url: 'https://console.prepress.cimpress.io',
      },
      {
        id: 'filereview',
        text: 'File Review',
        url: 'https://review.filereview.cimpress.io/',
      },
      {
        id: 'stitchx',
        text: 'StitchX',
        url: 'https://stitchx.embroidery.cimpress.io/',
      },
      {
        id: 'scenemaker',
        text: 'Scene Maker',
        url: 'https://scenemaker.cimpress.io/',
      },
      {
        id: 'designer',
        text: 'Cimpress Designer',
        url: 'https://designer.cimpress.io/',
      },
      {
        id: 'templatemaker',
        text: 'Template Maker',
        url: 'https://templatemaker.documents.cimpress.io/',
      },
    ],
  },
  {
    id: 'logistics',
    text: 'Logistics',
    svg: 'truck-1',
    subItems: [
      {
        id: 'logisticsConfiguration',
        text: 'Logistics Configuration',
        url: 'https://logistics.cimpress.io/',
      },
      {
        id: 'shippingConsole',
        text: 'Shipping Console',
        url: 'https://console.shipping.cimpress.io/',
      },
      {
        id: 'shippingManagementConsole',
        text: 'Shipping Management Console',
        url: 'https://my.shipping.cimpress.io/',
      },
      {
        id: 'shippingBoxes',
        text: 'Shipping Boxes',
        url: 'https://boxman.logistics.cimpress.io/',
      },
      {
        id: 'sortman',
        text: 'Sortman',
        url: 'https://sorting.cimpress.io/',
      },
      {
        id: 'quoterConfiguration',
        text: 'Carrier Contract Manager',
        url: 'https://qcm.qp.cimpress.io/',
      },
      {
        id: 'shippingRecommendations',
        text: 'Shipping Recommendations',
        url: 'https://shiprec.qp.cimpress.io/',
      },
      {
        id: 'productionTime',
        text: 'Production Time',
        url: 'https://productiontime.dnj.cimpress.io/ui/',
      },
    ],
  },
  {
    id: 'ecommerce',
    text: 'E-Commerce',
    svg: 'store',
    subItems: [
      {
        id: 'abc',
        text: 'ABC',
        url: 'https://admin.abc.cimpress.io/organizations',
      },
    ],
  },
  {
    id: 'analytics',
    text: 'Reporting and Analytics',
    svg: 'graph-pie-2',
    subItems: [
      {
        id: 'cimba-portal',
        text: 'Cimba Portal',
        url: 'https://cimba.cimpress.io/',
      },
      {
        id: 'river',
        text: 'River',
        url: 'https://console.river.cimpress.io/',
      },
      {
        id: 'transformer',
        text: 'Transformer',
        url: 'https://console.transformer.cimpress.io/',
      },
      {
        id: 'looker',
        text: 'Looker',
        url: 'https://cimpress.eu.looker.com/?utm_source=mcp_ui&utm_medium=hamburger&utm_campaign=link',
      },
      {
        id: 'dam',
        text: 'Data Usage Control Kit (DUCK)',
        url: 'https://pdw.cimpress.io/',
      },
    ],
  },
  {
    id: 'accounts',
    text: 'Account and Setup',
    svg: 'person-setting-2',
    subItems: [
      {
        id: 'accountManagementDashboard',
        text: 'Account Management Dashboard',
        url: 'https://accounts.cimpress.io/',
      },
      {
        id: 'coam',
        text: 'Access Management',
        url: 'https://users.cimpress.io/',
      },
      {
        id: 'fulfillerPortal',
        text: 'Fulfiller Portal',
        url: 'https://fulfillerportal.fi.cimpress.io/',
      },
      {
        id: 'fulfillerConfig',
        text: 'Fulfiller Configuration',
        url: 'https://fulfillerconfiguration.fulfillment.cimpress.io/',
      },
    ],
  },
  {
    id: 'apis',
    text: 'APIs',
    url: 'https://developer.cimpress.io/apis/',
    svg: 'folder-code',
  },
  {
    id: 'documentation',
    text: 'Documentation',
    url: 'https://developer.cimpress.io/docs/',
    svg: 'book-open-4',
  },
];
