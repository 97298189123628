import langs from 'langs';

const languages = [
  langs.where('1', 'de'),
  langs.where('1', 'en'),
  langs.where('1', 'fr'),
  langs.where('1', 'hi'),
  langs.where('1', 'nl'),
  langs.where('1', 'pl'),
  langs.where('1', 'it'),
  langs.where('1', 'ja'),
  langs.where('1', 'zh'),
];

export const languageCodes = languages.map(lang => lang['1']);

export const getLanguageOptions = supportedLanguagesCodes =>
  languages.map(lang => ({
    label: lang.local,
    value: lang['1'],
    disabled: !supportedLanguagesCodes.includes(lang['1']),
  }));
